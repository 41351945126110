const reA = /[^a-zA-Z-]/g;
const reN = /[^0-9]/g;
export const sortAlphaNum=(aa,bb)=>{
    var a=aa.toString().trim()
    var b=bb.toString().trim()
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
        var aN = parseInt(a.replace(reN, ""), 10);
        var bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
        return aA > bA ? 1 : -1;
    }
}
import React, { useState } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { StoreContext } from '../Store/StoreProvider'
import { AuthContext } from '../ProviderAuth/AuthProvider'
import RegistrarInfoUsuario from '../Camino usuario/RegistrarInfoUsuario'
import EsperaActivacion from '../Camino usuario/EsperaActivacion'
import ComprobandoAccesos from '../Camino usuario/ComprobandoAccesos'
import { onAuthStateChanged } from 'firebase/auth/cordova'
import { getAuth } from 'firebase/auth'
import { app } from '../../Firebase/firebase'
import { useNavigate } from 'react-router'

const CheckAcces = ({Component,props={},ruta="",texto="No tienes acceso a esta sección"}) => {
    const [authState]=useContext(AuthContext)
    const [accesos,setAccesos]=useState(authState.accesos||{})
    const navigate =useNavigate()
    useEffect(()=>{
        const accesosss=authState.accesos||{}
        setAccesos(accesosss)
        console.log(authState)
    },[authState])

    
    useEffect(()=>{
        const unsubscribe=onAuthStateChanged(getAuth(app),user=>{
            if (!user){
                navigate("/login")
            }
        })
        return ()=>{
            unsubscribe() 
        }
    },[navigate])



  return (
    <div className='w-full flex '>
        {authState.estadoUsuario=="buscando"?
            <ComprobandoAccesos/>
        :
            <>
                {authState.estadoUsuario=="no existe"?
                    <RegistrarInfoUsuario/>
                :
                    <>
                        {authState.estadoUsuario=="no activo"?
                            <EsperaActivacion/>
                        :
                            <>
                                {Object.keys(accesos).length?
                                    <>
                                        {accesos?.[ruta]||ruta==""?
                                            <Component props={props}/>
                                        :
                                            <div className='flex w-full justify-center'>
                                                <label>{texto}</label>
                                            </div>
                                        }
                                    </>
                                :
                                    <div>
                                        <label>Descargando datos de acceso</label>
                                    </div>
                                }
                            </>
                        }
                    </>
                }
            </>
        }
    </div>
  )
}

export default CheckAcces
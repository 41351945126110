// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {doc, getFirestore, setDoc} from "firebase/firestore"
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLfdWLH7S64djGhsnD2xZy0jiv4KVcfyk",
  authDomain: "rifas-test.firebaseapp.com",
  projectId: "rifas-test",
  storageBucket: "rifas-test.appspot.com",
  messagingSenderId: "776651342924",
  appId: "1:776651342924:web:833b0e6473628b007e60e9",
  measurementId: "G-HNGTRS7M9E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db=getFirestore(app)
const analytics = getAnalytics(app);
const storage=getStorage(app)

setPersistence(getAuth(app),browserLocalPersistence)




export {db,app,storage}
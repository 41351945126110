import React from 'react'
import { useParams } from 'react-router-dom'
import ListNavLink from '../Generales/ListNavLink'
import CheckAcces from '../Router/CheckAcces'

const AdministrarPrestamos = () => {
    const {menu}=useParams()
    return (
      <div className='bg-gray-100 w-full h-max flex '>
          <div className="bg-white rounded-md w-full flex flex-col h-full justify-start ">
              <h1 className=' justify-start flex mx-5 font-bold text-3xl mb-2'>Prestamos</h1>
              <nav className='mx-10'>
                  <ul className='flex w-full space-x-8 border-b pb-2 border-gray-300 overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                      <ListNavLink menu={menu} ruta='/prestamos' name="informacion" text="Activos"/>
                  </ul>
              </nav>
              <div className='menuabierto  flex w-full h-max p-5'>
                  {menu=="informacion"&&<CheckAcces Component={Test} ruta={""}/>}
              </div>
          </div>
      </div>
    )
}

const Test=()=>{
    return (
        <div>hey</div>
    )
}

export default AdministrarPrestamos
import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { db } from '../../Firebase/firebase'
import padWithZeros from '../Helpers/padWithZeros'
import formatDate from '../Helpers/formatDate'
import formatDateHora from '../Helpers/formatDateHora'
import { formatNumero } from '../Helpers/formatNumero'
import { CircularProgress, Progress, Spinner } from '@nextui-org/react'

const TicketDigital = () => {
    const location=useLocation()
    const queryLink=new URLSearchParams(location.search)
    const [queryId,setQueryId]=useState(queryLink.get("id")||"")
    const [queryIdRifa,setQueryIdRifa]=useState(queryLink.get("r")||"")
    const [ticket,setTicket]=useState({})
    const [buscando,setBuscando]=useState(true)
    const [infoRifa,setInfoRifa]=useState({})
    const [precios,setPrecios]=useState({})
    const [tiempoRestanteDeProntoPago,setTiempoRestanteDeProntoPago]=useState("")
    const [statusDelPago,setStatusDelPago]=useState("Verificando")

    useEffect(()=>{
        let intervalo=()=>{}
        if (Object.keys(ticket||{}).length){
            intervalo=setInterval(()=>{
                setTiempoRestanteDeProntoPago(tiempoRestantePara(ticket?.fecha?.seconds||0,infoRifa.horasDeProntoPago))
            },1000)
        }
        return ()=>clearInterval(intervalo)
    },[ticket,infoRifa])

    const diccSubfijo={
        1:"1er lugar",
        2:"2do lugar",
        3:"3er lugar",
        4:"4to lugar",
        5:"5to lugar",
        6:"6to lugar",
        7:"7mo lugar",
        8:"8vo lugar",
        9:"9no lugar",
        10:"10mo lugar"
    }

    useEffect(()=>{
        const unsubscriber=onSnapshot(doc(db,"rifas/"+queryIdRifa+"/compras",queryId),snap=>{
            if (snap.exists()){
                setTicket(snap.data())
            }
        })
        const unsubscriberRifa=onSnapshot(doc(db,"rifas/"+queryIdRifa+"/infoRifa","info"),snap=>{
            if (snap.exists()){
                setInfoRifa(snap.data())
            }
            setBuscando(false)
        })
        return ()=>{
            unsubscriber()
            unsubscriberRifa()
        }
    },[])

    useEffect(()=>{
        const keysPreciosEnOrden=Object.keys(ticket.precios||{}).sort((a,b)=>ticket.precios[b].boletos-ticket.precios[a].boletos)

        const precioFinal=keysPreciosEnOrden.reduce((a,key)=>{
            
            const boletos=ticket.precios?.[key]?.boletos||0
            const boletosDeRegalo=ticket.precios?.[key]?.regalo||0
            const boletosDeRegaloProntoPago=ticket.precios?.[key]?.regaloProntoPago||0
            const precioPorUnidad=parseFloat(ticket.precios?.[key]?.precio||0)
            let boletosRestantes=a.boletosRestantes
            let boletosRestantesProntoPago=a.boletosRestantesProntoPago
            let precio=a.precio
            let precioProntoPago=a.precioProntoPago
            let desgloseDePrecio=a.desgloseDePrecio||{}
            let desgloseDePrecioProntoPago=a.desgloseDePrecioProntoPago||{}
            if (boletosRestantes>=boletos){
                const repeticiones=Math.floor((boletosRestantes)/boletos)
                for(var i=0;i<repeticiones;i++){
                    const boletosRegalados=boletosRestantes-boletos-boletosDeRegalo>=0?boletosDeRegalo:boletosRestantes-boletos
                    boletosRestantes=boletosRestantes-boletos-boletosDeRegalo 
                    //const boletosRegalados=boletos-boletosDeRegalo>=(boletosRestantes)?boletosDeRegalo:0
                    precio=precio+(boletos*precioPorUnidad)
                    desgloseDePrecio={...desgloseDePrecio,[key]:{boletos:boletos*(i+1),precio:precioPorUnidad,regalo:boletosRegalados,total:(boletos*precioPorUnidad)}}
                    if (boletosRestantes<boletos){
                        break;
                    }
                }
            } 

            if (boletosRestantesProntoPago>=boletos){
                const repeticiones=Math.floor((boletosRestantesProntoPago)/boletos)
                for(var i=0;i<repeticiones;i++){ 
                    const boletosRegalados=boletosRestantesProntoPago-boletos-boletosDeRegalo-boletosDeRegaloProntoPago>=0?boletosDeRegalo+boletosDeRegaloProntoPago:boletosRestantesProntoPago-boletos
                    boletosRestantesProntoPago=boletosRestantesProntoPago-boletos-boletosDeRegalo-boletosDeRegaloProntoPago 
                    precioProntoPago=precioProntoPago+(boletos*precioPorUnidad)
                    desgloseDePrecioProntoPago={...desgloseDePrecioProntoPago,[key]:{boletos:boletos*(i+1),precio:precioPorUnidad,regalo:boletosRegalados,total:(boletos*precioPorUnidad)}}
                    
                    if (boletosRestantesProntoPago<boletos){
                        break;
                    }
                }
            }
            return {precio,boletosRestantes,precioProntoPago,boletosRestantesProntoPago,desgloseDePrecio,desgloseDePrecioProntoPago} 

        },{precio:0,boletosRestantes:(ticket.numerosCobrados||[]).length,precioProntoPago:0, boletosRestantesProntoPago:(ticket.numerosCobrados||[]).length,desgloseDePrecio:{},desgloseDePrecioProntoPago:{}})
        const desgloseATexto=(desgloses)=>{
            const keysOrdenadas=Object.keys(desgloses||{}).sort((b,a)=>desgloses[a].boletos-desgloses[b].boletos)
            const texto=keysOrdenadas.map(key=>desgloses[key].boletos+(desgloses[key].boletos==1?" boleto":" boletos")+" x $"+formatNumero(desgloses[key].precio)+" = $"+formatNumero(desgloses[key].total)+(desgloses[key].regalo>0?" (+"+desgloses[key].regalo+" de regalo)":""))
            return texto
        }
        const textoDesglosePrecio=desgloseATexto(precioFinal.desgloseDePrecio||{})
        const textoDesglosePrecioProntoPago=desgloseATexto(precioFinal.desgloseDePrecioProntoPago||{})
        setPrecios({...precioFinal,textoDesglosePrecio,textoDesglosePrecioProntoPago})
        
    },[ticket])

    useEffect(()=>{
        if (Object.keys(precios||{}).length &&Object.keys(ticket||{}).length){
            const precio=precios.precio>=ticket.pago?precios.precio:precios.precioProntoPago
            const restante=precio-ticket.pago
            if (parseInt(ticket.pago||0)==0){
                setStatusDelPago("Pago pendiente")
            }else if (restante>0){
                setStatusDelPago("Pago parcial")
            }else if (ticket.pago>=precio){
                setStatusDelPago("Pagado")
            }
        }
    },[precios,ticket])

    useEffect(()=>{
        console.log(statusDelPago)
    },[statusDelPago])

    function tiempoRestantePara(fechaEnSegundos, horasASumar) {
        // Convertir la fecha en segundos a milisegundos
        const fechaInicialEnMs = fechaEnSegundos * 1000;
    
        // Crear una fecha a partir del timestamp
        const fechaInicial = new Date(fechaInicialEnMs);
    
        // Sumar las horas especificadas
        const futura = new Date(fechaInicial.getTime() + horasASumar * 60 * 60 * 1000);
    
        // Obtener la fecha y hora actual
        const ahora = new Date();
    
        // Calcular la diferencia en milisegundos
        const diferencia = futura - ahora;
    
        // Si la diferencia es negativa, significa que el tiempo ya ha pasado
        if (diferencia < 0) {
            return "0h 00m 00s"
        }
    
        // Convertir la diferencia en horas, minutos y segundos
        let horasRestantes = Math.floor(diferencia / (1000 * 60 * 60));
        let minutosRestantesOriginales = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
        let segundosRestantesOriginales = Math.floor((diferencia % (1000 * 60)) / 1000);
        const segundosRestantes=segundosRestantesOriginales<10?"0"+segundosRestantesOriginales:segundosRestantesOriginales
        const minutosRestantes=minutosRestantesOriginales<10?"0"+minutosRestantesOriginales:minutosRestantesOriginales
        if (horasRestantes<=0&&minutosRestantesOriginales<=0&&segundosRestantesOriginales<=0){
            return "0h 00m 00s"
        }
        return `${horasRestantes}h ${minutosRestantes}m ${segundosRestantes}s`;
    }
    


  return (
    <div className='bg-gray-100 w-full h-max flex px-10 py-5  justify-center '>
        <div className=' max-w-md w-full min-w-max flex flex-col items-center border border-gray-200 rounded-xl bg-white shadow-lg'>
            <div className=' bg-green-700 text-white rounded-t-xl w-full flex justify-center gap-2'>
                <i className="fa-regular fa-circle-check text-3xl "></i>
                <label className='font-bold text-2xl border-b border-gray-800 '>Ticket Digital</label>
            </div>
            <StatusDelPago statusDelPago={statusDelPago}/>
            <div className='border-b-2 border-dashed border-gray-600 w-full flex flex-col gap-2 py-2'>
                <div className='flex flex-col w-full items-center'>
                    <label className=''>Rifa</label>
                    <span className='font-bold text-lg'>{infoRifa.nombre}</span>
                </div>
                <div className='flex flex-col w-full items-center  bg-gradient-to-t  from-amber-200 to-white  shadow-md'>
                    <label className=''>Premios</label>
                    {(infoRifa.premios||[]).map((premio,i)=>
                        <label key={i} className='font-bold text-lg'><span className='text-sm mr-2'>{diccSubfijo[i+1]}:</span>{premio||""}</label>
                    )}
                </div>
                <div className='flex flex-col w-full items-center'>
                    <label className='text-sm'>Fecha de resultados:</label>
                    <span className='font-semibold'>{formatDate((infoRifa?.caducidad?.seconds||0)*1000)}</span>
                </div>
            </div>
            <div className='px-10 flex flex-col my-4'>
                <div className='flex flex-col items-center'>
                    <label>Nombre:</label>
                    <span className='text-lg font-semibold'>{ticket.nombre}</span>
                </div>
                <div className='flex flex-col items-center'>
                    <label>Teléfono:</label>
                    <span className='text-lg font-semibold'>{"*******"+((ticket.telefono||"").slice((ticket.telefono||"").length-3,(ticket.telefono||"").length))}</span>
                </div>
                <div className='flex flex-col items-center'>
                    <label>Fecha de adquisición:</label>
                    <label className=' font-semibold'>{formatDate((ticket?.fecha?.seconds||0)*1000)+" a las "+formatDateHora((ticket?.fecha?.seconds||0)*1000)} hrs.</label>
                </div>
            </div>
            <div className='flex flex-col   border-t border-gray-600 w-full items-center'>
                <label className='bg-slate-800 text-white w-full text-center font-bold'>Números adquiridos ({(ticket.numerosCobrados||[]).length})</label>
                <ul className=' list-inside list-disc my-4'>
                    {(ticket.numerosCobrados||[]).map(num=>
                        <li key={num} className='font-semibold'>{padWithZeros(num,infoRifa.cifras)}</li>
                    )}
                </ul>
            </div>
            {(ticket.numerosNoCobrados||[]).length>0&&
                <div className='flex flex-col   border-t border-gray-600 w-full items-center'>
                    <label className='bg-red-800 text-white w-full text-center font-bold'>Números NO Aceptados ({(ticket.numerosNoCobrados||[]).length})</label>
                    <span className='  max-w-sm px-4 justify-center flex text-xs'>Estos números los seleccionaste pero fueron seleccionados por otro usuario mientras completabas el proceso de apartado de tus boletos, estos boletos no fueron cobrados</span>
                    <ul className=' list-inside list-disc my-4'>
                        {(ticket.numerosNoCobrados||[]).map(num=>
                            <li key={num} className='font-semibold'>{padWithZeros(num,infoRifa.cifras)}</li>
                        )}
                    </ul>
                </div>
            }
            <div className='flex w-full mb-4 flex-col items-center'>
                <label className='border-y-2 border-dashed border-gray-600 w-full text-center font-bold shadow-md'>Pago</label>
                <label className='text-sm mt-2'><i className="fa-solid fa-check text-green-600 mr-2"></i>Estás viendo información actualizada</label>
                <div className='flex flex-col w-full justify-around gap-4  py-2 '>
                    <div className='flex flex-col  px-2 rounded-md py-2'>
                        <div className='flex flex-col items-center gap-x-2'>
                            <label>Precio total:</label>
                            <span className='font-bold text-2xl'>${formatNumero(precios.precio)}</span>
                        </div>
                        <label className='text-xs w-full text-center'>Detalle de cobro:</label>
                        <ul className='flex flex-col items-center list-disc'>
                            {(precios.textoDesglosePrecio||[]).map((texto,i)=>
                                <li key={i}>{texto}</li>
                            )}
                        </ul>
                    </div>
                    {precios.precio>precios.precioProntoPago&&
                        <div className='flex flex-col border border-gray-400 px-2 rounded-md'>
                            <div className='flex flex-col items-center gap-x-2'>
                                <label>Precio de pronto pago:</label>
                                <span className='font-bold'>${formatNumero(precios.precioProntoPago)}</span>
                            </div>
                            <div className='flex flex-col py-2'>
                                <label className='text-xs w-full text-center'>Detalle de cobro:</label>
                                <ul className='flex flex-col items-center list-disc'>
                                    {(precios.textoDesglosePrecioProntoPago||[]).map((texto,i)=>
                                        <li key={i}>{texto}</li>
                                    )}
                                </ul>
                            </div>
                            <div className='flex flex-col items-center'>
                                <label className='text-xs'>Tiempo restante de pronto pago</label>
                                <span className=' animate-pulse font-semibold text-orange-500'>{tiempoRestanteDeProntoPago}</span>
                            </div>
                        </div>
                    }
                </div>
                <StatusDelPago statusDelPago={statusDelPago}/>
                <div className='flex flex-col items-center border-t border-gray-400 w-full py-2'>
                    <label>Tu pago</label>
                    <span className='font-bold  text-2xl'>${formatNumero(ticket.pago||0)}</span>
                </div>
            </div>
        </div>
    </div>
  )
}

const StatusDelPago=({statusDelPago})=>{
    return(
        <div className={`${statusDelPago=="Verificando"&&"bg-white border-b border-gray-400"} ${statusDelPago=="Pagado"&&"bg-green-500 text-white"} ${statusDelPago=="Pago parcial"&&"bg-orange-400"} ${statusDelPago=="Pago pendiente"&&"bg-red-500 text-white"}  w-full text-center shadow-md shadow-gray-400 font-semibold flex gap-2 justify-center`}>
            <span >{statusDelPago}</span>
            {statusDelPago=="Verificando"&&
                <Spinner color="current" size='sm'/>
            }
        </div>
    )
}

export default TicketDigital
export default function handleInputNumeric(value, allowDecimals = true, allowNegatives = false) {
  let newValue = value;

  if (allowDecimals) {
      // Acepta números y solo un punto decimal
      if (!/^-?\d*\.?\d*$/.test(newValue)) {
          // Si no es válido, elimina caracteres no numéricos y más de un punto decimal
          newValue = newValue.replace(/[^\d.-]/g, '').replace(/(\..*)\./g, '$1');
      }
      // Elimina ceros al inicio, pero permite decimales como "0.1115"
      newValue = newValue.replace(/^0+([1-9])/, '$1');
  } else {
      // Solo acepta números enteros (positivos o negativos)
      if (!/^-?\d+$/.test(newValue)) {
          // Si no es válido, elimina caracteres no numéricos
          newValue = newValue.replace(/\D/g, '');
      }
      // Elimina ceros al inicio
      newValue = newValue.replace(/^0+/, '');
  }

  if (!allowNegatives) {
      // Si no se permiten números negativos, elimina el signo negativo al inicio
      newValue = newValue.replace(/^-/, '');
  }

  return newValue;
}
const formatDate=(fecha,soloNumeros=false)=>{
  if (fecha){
    const meses=["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"]
    let date = new Date(fecha)
    let day = date.getDate()
    let mesNum=date.getMonth()
    let month = meses[mesNum]
    let year = date.getFullYear()
  
    if (soloNumeros){
      if(mesNum+1 < 10){
        return(`${day}/0${mesNum+1}/${year}`)
      }else{
        return(`${day}/${mesNum+1}/${year}`)
      }
    }
    return(`${day}/${month}/${year}`)
  }
  return ""
}

export default formatDate
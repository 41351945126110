import { Spinner } from '@nextui-org/react'
import React from 'react'

const ComprobandoAccesos = () => {
  return (
    <div
        className='flex flex-col w-full justify-center items-center'
    >
        <label>Estamos verificando tus accesos</label>
        <Spinner/>
    </div>
  )
}

export default ComprobandoAccesos
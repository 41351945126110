import React, { useEffect } from 'react'
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import {getAuth} from "firebase/auth"
import {app} from "../../Firebase/firebase"
import 'firebaseui/dist/firebaseui.css'
import LOGO  from "../Fotos/LOGO.jpg"

const Login = () => {

    useEffect(() => {
        const ui =firebaseui.auth.AuthUI.getInstance() ||new firebaseui.auth.AuthUI(getAuth(app));
        ui.disableAutoSignIn();
        ui.start("#firebaseui-auth-container",{
          signInSuccessUrl: "/prestamos/informacion",
          signInFlow: 'popup',
          signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            {
              provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              clientId:
                "156665833155-j65ise6faiur7j7tkgvgogr7t47fbkgk.apps.googleusercontent.com",
            },
            {
              provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            },
            // leave for ANOTHER video
            // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
          ],
          // required to enable one-tap sign-up credential helper
          credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
        });
      }, []);
    
      return (
        <div className='w-full flex-col h-screen justify-center items-center flex from-gray-900 via-gray-900 bg-gradient-to-t  to-sky-900'>
            <div className='flex flex-col items-center   h-full'>
                <div className=' h-max mt-5  flex flex-col items-center'>
                </div>
                <div className='flex-grow flex items-center '>
                    <div className='border px-2 border-gray-200 shadow-md rounded-md flex flex-col items-center text-white h-fit bg-gray-800'>
                        <label className='border-b border-white font-bold'>Inicio de sesión</label>
                        <span className='text-xs'>Porfavor selecciona un método de inicio de sesión</span>
                        <div id="firebaseui-auth-container"></div>
                    </div>
                </div>
            </div>
        </div>
      );
}

export default Login
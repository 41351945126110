import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../Firebase/firebase'
import { sortAlphaNum } from '../Helpers/sortAlphaNum'
import formatearNumeroTelefono from '../Helpers/formatearNumeroDeTelefono'

const PieDePagina = ({idEmpresa="ComjGl0wUhoDTy2mxQZp"}) => {
    const [infoEmpresa,setInfoEmpresa]=useState({})
    const [redesSociales,setRedesSociales]=useState([])
    const [keysNumerosOrganizados,setKeysNumerosOrganizados]=useState([])

    useEffect(()=>{
        const unsubscriber=onSnapshot(doc(db,"empresas",idEmpresa), res=>{
            setInfoEmpresa(res.data())
            const keysRedesOrdenadas=Object.keys(res.data().redesSociales||{}).sort((a,b)=>sortAlphaNum(res.data().redesSociales[a].nombre,res.data().redesSociales[b].nombre))
            const keysNumerosOrd=Object.keys(res.data().numerosWhatsapp||{}).sort((a,b)=>sortAlphaNum(res.data().numerosWhatsapp[a].numero,res.data().numerosWhatsapp[b].numero))
            //const objRedesOrdenado=keysRedesOrdenadas.reduce((a,key)=>({...a,[key]:res.data().redesSociales[key]}),{})
            setRedesSociales(keysRedesOrdenadas)
            setKeysNumerosOrganizados(keysNumerosOrd)
        })
        return ()=>unsubscriber()
    },[])

  return (
    <div className='w-full flex flex-col bg-gray-800 mt-5 py-5 text-white px-5 text-xs'>
        <div className='flex flex-col gap-3'>
            <label>{infoEmpresa.nombre}</label>
            <label>{infoEmpresa.quienesSomos}</label>
            <label className=' text-amber-400 font-semibold border-b border-gray-500'>Conoce más sobre nosotros</label>
            <div className='flex w-full gap-20 flex-wrap'>
                <div className='flex flex-col gap-1'>
                    <label className='text-xs text-amber-400'>Nuestras redes sociales</label>
                    <div className='flex flex-col gap-1'>
                        {redesSociales.map(key=>
                            <a key={key} className=' hover:underline' href={infoEmpresa.redesSociales[key].url} target="_blank" rel="noopener noreferrer">{infoEmpresa.redesSociales[key].nombre}</a>
                        )}
                    </div>
                </div>
                <div className='flex flex-col gap-1'>
                    <label className='text-xs text-amber-400'>Contacto por whatsapp</label>
                    <div className='flex flex-col gap-1'>
                        {keysNumerosOrganizados.map((key,i)=>
                            <a className=' hover:underline' href={"https://wa.me/"+infoEmpresa.numerosWhatsapp[key].numero} target="_blank" rel="noopener noreferrer">{(infoEmpresa.numerosWhatsapp[key].nombre||"Contacto No. "+(i+1))}</a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PieDePagina
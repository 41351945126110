export function formatNumero(numero) {
  var signo = numero < 0 ? "-" : ""; // Manejar el signo por separado
    numero = Math.abs(numero); // Tomar el valor absoluto del número
    numero = numero.toString();  // Convertir el número a una cadena de texto
    var partes = numero.split('.');  // Dividir la cadena en parte entera y parte decimal
  
    // Agregar comas a la parte entera
    var parteEntera = partes[0];
    var longitud = parteEntera.length;
    var resultado;
  
    if (longitud <= 3) {
        resultado = parteEntera;  // Si la parte entera es menor o igual a 3 dígitos, no se necesitan comas
    } else {
        var primeraComa = longitud % 3;
  
        if (primeraComa === 0) {
            primeraComa = 3;
        }
  
        resultado = parteEntera.substr(0, primeraComa);
  
        while (primeraComa < longitud) {
            resultado += ',' + parteEntera.substr(primeraComa, 3);
            primeraComa += 3;
        }
    }
  
    // Agregar dos ceros al final si el número es entero
    if (partes.length === 1) {
        resultado += '.00';
    } else if (partes.length > 1) {
        // Combinar la parte entera con la parte decimal
        resultado += '.' + partes[1].padEnd(2, '0');
    }
  
    // Agregar el signo al resultado
    resultado = signo + resultado;

    return resultado;
    /*numero = numero.toString();  // Convertir el número a una cadena de texto
    var partes = numero.split('.');  // Dividir la cadena en parte entera y parte decimal
  
    // Agregar comas a la parte entera
    var parteEntera = partes[0];
    var longitud = parteEntera.length;
    var resultado;
  
    if (longitud <= 3) {
      resultado = parteEntera;  // Si la parte entera es menor o igual a 3 dígitos, no se necesitan comas
    } else {
      var primeraComa = longitud % 3;
  
      if (primeraComa === 0) {
        primeraComa = 3;
      }
  
      resultado = parteEntera.substr(0, primeraComa);
  
      while (primeraComa < longitud) {
        resultado += ',' + parteEntera.substr(primeraComa, 3);
        primeraComa += 3;
      }
    }
  
    // Combinar la parte entera con la parte decimal (si existe)
    if (partes.length > 1) {
      resultado += '.' + partes[1];
    }
  
    //console.log(resultado+"=="+parseInt(resultado,10))
    return resultado//redondear?Math.round(parseFloat(resultado,10)):resultado;*/
  }
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import React, { createContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { app, db } from '../../Firebase/firebase'
import reducerAuth, { initialStateAuth } from './authReducer'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'

const AuthContext=createContext()

const AuthProvider = ({children}) => {
    const [store, dispatch] = useReducer(reducerAuth, initialStateAuth)
    const navigate =useNavigate()

    useEffect(()=>{
        if ((store?.user?.uid)){
            //Si está autenticado entonces verifica el estado del usuario
            const unsubscriberEstadoUsuario=onSnapshot(doc(db,"usuarios",store.user.uid),res=>{
                if (res.exists()){
                    const objUsuario=res.data()
                    dispatch({type:"setCortesPermitidos",payload:(objUsuario.cortesPermitidos||[])})
                    dispatch({type:"setAccesos",payload:(objUsuario.accesos||{})})
                    if (objUsuario.activo){
                        dispatch({type:"setEstadoUsuario",payload:"activo"})
                    }else{
                        dispatch({type:"setEstadoUsuario",payload:"no activo"})
                    }
                }else{
                    dispatch({type:"setCortesPermitidos",payload:[]})
                    dispatch({type:"setAccesos",payload:{}})
                    dispatch({type:"setEstadoUsuario",payload:"no existe"})
                }
            })
            return ()=>{
                unsubscriberEstadoUsuario()
            }
        }else{
            dispatch({type:"setCortesPermitidos",payload:[]})
            dispatch({type:"setEstadoUsuario",payload:"fuera"})
            dispatch({type:"setAccesos",payload:{}})
        }
    },[store.user])

    useEffect(()=>{
        const unsubscribe=onAuthStateChanged(getAuth(app),user=>{
            if (!user){
                dispatch({type:"setAuth",payload:null});
                //navigate("/login")
            }
            dispatch({type:"setAuth",payload:user});
        })
        return ()=>{
            unsubscribe() 
        }
    },[navigate])
  return (
    <AuthContext.Provider value={[store,dispatch]}>
        {children}
    </AuthContext.Provider>
  )
}
export {AuthContext}
export default AuthProvider
export default  function updateBitMap(bitMap, numbers) {
    // Inicializar el bitMap como un array de bits
    let bitMapArray = bitMap.split('').map(bit => parseInt(bit, 10));

    // Ajustar el tamaño del bitMap si es necesario
    const maxIndex = Math.max(...numbers, bitMapArray.length - 1);
    if (bitMapArray.length <= maxIndex) {
        bitMapArray = bitMapArray.concat(new Array(maxIndex - bitMapArray.length + 1).fill(0));
    }

    const alreadyPresent = [];
    const numerosSubidos = [];

    numbers.forEach(number => {
        if (bitMapArray[number] === 1) {
            alreadyPresent.push(number);
        } else {
            bitMapArray[number] = 1;
            numerosSubidos.push(number);
        }
    });

    // Convertir el array de bits de vuelta a una cadena
    const updatedBitMap = bitMapArray.join('');

    return {
        newBitMap: updatedBitMap,
        numerosRepetidos: alreadyPresent,
        numerosSubidos: numerosSubidos
    };
}
  function bitmapToActiveNumbers(bitmap="") {
    let activeNumbers = [];
    
    for (let i = 0; i < bitmap.length; i++) {
        if (bitmap[i] === '1') {
            activeNumbers.push(i);
        }
    }
    
    return activeNumbers;
}

export {bitmapToActiveNumbers}
import React from 'react'
import { NavLink } from 'react-router-dom'

const ListNavLink = ({menu="NAA",name="", text="", ruta=""}) => {
  return (
    <li>
        <NavLink
            exact to={ruta+"/"+name}
            name={name}
            className={` pb-2  px-3 ${menu==name&& "border-b-4 border-blue-600"} `}
        >
            {text}
        </NavLink>
    </li>
  )
}

export default ListNavLink
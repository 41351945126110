import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../ProviderAuth/AuthProvider'
import {Navbar, NavbarBrand, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, NavbarContent, NavbarItem, Link, Button} from "@nextui-org/react";
import { getAuth } from 'firebase/auth';
import { app, db } from '../../Firebase/firebase';
import LOGO from "../Fotos/LOGO.png"
import { NavLink } from 'react-router-dom';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { generarId } from '../Helpers/generarId';
const NavBar = () => {
  const [menuisActive,setMenuIsActive]=useState(false) 
    const [auth]=useContext(AuthContext)
    const menuItems = [
        "Clientes",
        "Configuraciones",
    ];

    const handleCerrarSesion=()=>{
        const auth=getAuth(app)
        auth.signOut()
    }

    useEffect(()=>{
      if (auth?.user?.uid){
        setDoc(doc(db,"registroDeEntradas",auth.user.uid),{[generarId()]:new Date()},{merge:true})
      }
    },[auth.user])

  return (
    <>
        {!!auth.user&&
            <Navbar className='from-gray-900 to-sky-900 bg-gradient-to-t text-white' disableAnimation isBordered isBlurred shouldHideOnScroll onMenuOpenChange={(e)=>setMenuIsActive(e)} isMenuOpen={menuisActive}>
            <NavbarContent className="sm:hidden" justify="start">
              <NavbarMenuToggle />
            </NavbarContent>
      
            <NavbarContent className="sm:hidden pr-3" justify="center">
              <NavbarBrand className=' w-28'>
                {/*<img src={LOGO}/>*/}
              </NavbarBrand>
            </NavbarContent>
      
            <NavbarContent className="hidden sm:flex gap-4" justify="center">
              <NavbarBrand className=" w-28 ">
                {/*<img className="" src={LOGO}/>*/}
              </NavbarBrand>
              <NavbarItem>
                <NavLink color="foreground" exact to="/prestamos/informacion">
                  <div className='flex flex-col items-center'>
                    <i className="fa-solid fa-building-user text-xl"></i>
                    <label className='text-xs'>Prestamos</label>
                  </div>
                </NavLink>
              </NavbarItem>
              <NavbarItem>
                <NavLink color="foreground" exact to="/bancas/resumen">
                  <div className='flex flex-col items-center'>
                    <i className="fa-solid fa-vault text-xl"></i>
                    <label className='text-xs'>Bancas</label>
                  </div>
                </NavLink>
              </NavbarItem>
            </NavbarContent>
      
            <NavbarContent justify="end">
              <NavbarItem>
                <button onClick={handleCerrarSesion} className="rounded-md px-3 py-1 text-white gap-2 font-semibold text-sm flex items-baseline">
                  Cerrar sesión
                </button>
              </NavbarItem>
            </NavbarContent>
      
            <NavbarMenu>
                
                <NavbarItem
                  onClick={()=>setMenuIsActive(false)}
                >
                  <NavLink color="foreground" exact to="/prestamos/informacion">
                    <div className='flex items-center gap-4'>
                      <i className="fa-solid fa-building-user text-xl"></i>
                      <label >Prestamos</label>
                    </div>
                  </NavLink>
                </NavbarItem>
                <NavbarMenuItem
                  onClick={()=>setMenuIsActive(false)}
                >
                  <NavLink color="foreground" exact to="/bancas/resumen">
                    <div className='flex items-center gap-4'>
                      <i className="fa-solid fa-vault text-xl"></i>
                      <label className=''>Bancas</label>
                    </div>
                  </NavLink>  
                </NavbarMenuItem>
            </NavbarMenu>
          </Navbar>
        }
    </>
  )
}

export default NavBar
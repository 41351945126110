import React, { useContext, useState } from 'react'
import { AuthContext } from '../ProviderAuth/AuthProvider'
import swal from 'sweetalert'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../Firebase/firebase'

const RegistrarInfoUsuario = () => {
    const [auth]=useContext(AuthContext)
    const [nombre,setNombre]=useState(auth.user?.displayName||"")

    const handleSolicitarAcceso=()=>{
        if (!nombre){
            swal({
                title:"Debes introducir un nombre",
                icon:"error"
            })
            return
        }
        setDoc(doc(db,"usuarios",auth.user.uid),{nombre,activo:false,email:auth.user?.email||""})
    }
  return (
    <div
        className='flex flex-col w-full items-center'
    >
        <div
            className='border-b-2 border-sky-600 w-full flex justify-center'
        >
            <label className='font-semibold'>Detectamos que eres un nuevo usuario</label>
        </div>
        <div
            className='flex flex-col gap-4 border border-gray-400 p-2 rounded-md mt-5'
        >
            <div
                className='flex flex-col items-center mt-2'
            >
                <label className='text-sm'>Ingresaste con el correo: </label>
                <span className='font-semibold'>{auth.user.email}</span>
            </div>
            <div
                className='flex flex-col items-center'
            >
                <label>¿Cuál es tu nombre?</label>
                <input
                    onChange={(e)=>setNombre(e.target.value)}
                    value={nombre}
                    className='border border-gray-400 rounded-md shadow-md px-2 w-full'
                />
            </div>
            <div
                className='flex justify-center mt-5 py-5 border-t border-gray-400'
            >
                <button
                    onClick={()=>handleSolicitarAcceso()}
                    className='bg-blue-600 text-white px-2 rounded-md font-semibold'
                >
                    Solicitar acceso
                </button>
            </div>
        </div>
    </div>
  )
}

export default RegistrarInfoUsuario
const formatDateHora=(fecha)=>{
  if (fecha){
    let date = new Date(fecha)
    const horas=date.getHours()<10?"0"+date.getHours():date.getHours()
    const minutos=date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes()
    const segundos=date.getSeconds()<10?"0"+date.getSeconds():date.getSeconds()
    return  horas + ':' + minutos ;
  }
  return ""
}

export default formatDateHora
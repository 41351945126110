import { createContext, useEffect, useReducer } from "react";
import storeReducer, { initialStore } from "./storeReducer";
import { arrayUnion, collection, doc, getDoc, increment, onSnapshot, runTransaction, setDoc, Timestamp, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "../../Firebase/firebase";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import asyncForEach from "../Helpers/asyncForEach";
import { generarId } from "../Helpers/generarId";
import updateBitMap from "../Helpers/funcionesConversionesBit";

const StoreContext=createContext()
const StoreProvider = ({children}) => {
const [store, dispatch] = useReducer(storeReducer, initialStore)
const navigate=useNavigate()


useEffect(()=>{
  let unsubscriberPrestamos=()=>{}
  let unsubscriberUsuarios=()=>{}
  let unsubscriberBancas=()=>{}
  const obtieneListeners=async ()=>{
    unsubscriberUsuarios=DatosCollection("usuarios")
    unsubscriberPrestamos=DatosCollection("prestamos")
    unsubscriberBancas=DatosCollection("bancas")
  }
  obtieneListeners()
  return ()=>{
    unsubscriberUsuarios()
    unsubscriberPrestamos()
    unsubscriberBancas()
  }
},[])

  async function dispatchWrite ({type,payload}){
    switch (type) {
      case "apartarBoletos":
      await runTransaction(db,async trans=>{
        const {numerosSeleccionados,telefono,nombre,estado,municipio,idRifa}=payload
        const docBoletosUsados=await trans.get(doc(db,"rifas/"+idRifa+"/numerosOcupados","info"))
        const docInfoRifa=await trans.get(doc(db,"rifas/"+idRifa+"/infoRifa","info"))
          const bitMap=docBoletosUsados.data()?.info||""
          const resultado=updateBitMap(bitMap,numerosSeleccionados||[])
          trans.set(doc(db,"rifas/"+idRifa+"/numerosOcupados","info"),{info:resultado.newBitMap})
          const idCompra=generarId()
          resultado.numerosSubidos.forEach(numero=>{
            //Sube los numeros 
            trans.set(doc(db,"rifas/"+idRifa+"/infoNumeros",numero.toString()),{nombre,telefono,estado,municipio,fecha:Timestamp.now(),idCompra})
          })
          const infoRifa=docInfoRifa.data()
          trans.set(doc(db,"rifas/"+idRifa+"/compras",idCompra),{precios:infoRifa.precios,idCompra,nombre,telefono,estado,fecha:Timestamp.now(),municipio,numerosSeleccionados,numerosCobrados:resultado.numerosSubidos,numerosNoCobrados:resultado.numerosRepetidos})
          return {...resultado,idCompra,idRifa}
        
      }).then(resultado=>{
        payload.callBack(resultado)
      })

        break;
      default:
        break;
    }
  }

  const DatosCollection=(name)=>{
    return onSnapshot(collection(db,name),{ includeMetadataChanges: true },res=>{
      const source = res.metadata.hasPendingWrites ? "Local" : "Server";
      if (source==="Server"){ 
          var formatoObj={}
          res.forEach(item=>{
            formatoObj={...formatoObj,[item.id]:item.data()}
          })
          console.log({[name]:formatoObj})
          dispatch({type:name,payload:formatoObj})
      }
    })
  }

  return (
    <StoreContext.Provider value={[store,dispatch,dispatchWrite]}>
        {children}
    </StoreContext.Provider>
  )
}
export {StoreContext}
export default StoreProvider
import React from 'react'

const EsperaActivacion = () => {
  return (
    <div className='w-full flex justify-center mt-4'>
        <label
            className=' border-b-2 border-sky-600 w-full text-center'
        >Informa a un administrador requieres la activación de tu cuenta</label>
    </div>
  )
}

export default EsperaActivacion
import logo from './logo.svg';
import './App.css';
import Test from './componentes/Test';
import { Button, NextUIProvider, Switch } from '@nextui-org/react';
import AppRouter from './componentes/Router/AppRouter';
import StoreProvider from './componentes/Store/StoreProvider';
import AuthProvider from './componentes/ProviderAuth/AuthProvider';

function App() {
  return (
    <div className=''>
      <NextUIProvider>
          <AppRouter/>
      </NextUIProvider>
    </div>
  );
}

export default App;

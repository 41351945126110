export default function padWithZeros(number, length){
    // Convert the number to a string
    let str = number.toString();
    
    // Calculate how many zeros need to be added
    let zerosNeeded = length - str.length;
    
    // Add the necessary zeros to the start of the string
    for (let i = 0; i < zerosNeeded; i++) {
        str = '0' + str;
    }
    
    return str;
}
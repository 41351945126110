const initialStateAuth={
    user:{},
    estadoUsuario:"buscando",
    accesos:{
        administrador:false,
        recepcionista:false
    }
}

const reducerAuth=(state,action)=>{
    switch (action.type) {
        case "setCortesPermitidos":
            return {...state,cortesPermitidos:action.payload}
        case "setAuth":
            return {...(state||{}),user:action.payload}
        case "setEstadoUsuario":
            return {...state,estadoUsuario:action.payload}
        case "setAccesos":
            return {...state,accesos:Object.keys(action.payload).length?action.payload:{administrador:false,recepcionista:false}}
        default:
            return state
    }
}
export {initialStateAuth}
export default reducerAuth
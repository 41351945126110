import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Login from '../Login/Login'
import Home from '../Home/Home'
import AuthProvider from '../ProviderAuth/AuthProvider'
import NavBar from '../NavBar/NavBar'
import NoFoundPage from '../NoFound/NoFoundPage'
import StoreProvider from '../Store/StoreProvider'
import AdministrarPrestamos from '../Prestamos/AdministrarPrestamos'
import NavBarPublico from '../NavBar/NavBarPublico'
import TicketDigital from '../Check Ticket/TicketDigital'


const AppRouter = () => {
  return (
    <div className='flex flex-col w-full h-full'>
      <BrowserRouter className="">
          <div> </div>
        <AuthProvider>
          <StoreProvider>
            <NavBarPublico/>
            <NavBar/>
            <Routes>
              <Route path='/' Component={Home}/>
              <Route path='/prestamos/:menu' Component={AdministrarPrestamos}/>
              <Route path='/login' Component={Login}/>
              <Route path="/ticketDigital" Component={TicketDigital}/>
              <Route path='/*' Component={NoFoundPage}/>
            </Routes>
          </StoreProvider>
        </AuthProvider>
      </BrowserRouter>
    </div> 
        
  )
}

export default AppRouter
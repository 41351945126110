import { doc, increment, runTransaction, setDoc, updateDoc, writeBatch } from "firebase/firestore"
import { db } from "../../Firebase/firebase"
import swal from "sweetalert"
import generadorDatosPagos from "../Helpers/generadorDatosPagos"
import { generarId } from "../Helpers/generarId"
const initialStore={
}

const storeReducer= (state,action)=>{
    const payload=action.payload
    switch (action.type) {
        case "bancas":
            return {...state,bancas:action.payload}
        case "prestamos":
            return {...state,prestamos:action.payload}
        case "usuarios":
            return {...state,usuarios:action.payload}
        case "setNuevoPrestamo":
            var {id, state:obj,callBack}=payload
            var partesFecha =obj.fechaDePago.split("-");
            var diasDeCorte = partesFecha[2];
            setDoc(doc(db,"prestamos",id),{...obj,diasDeCorte}).then(()=>{
                callBack(true)   
            })
            return state
        case "setEditarPrestamo":
            var {id, state:obj,callBack}=payload
            var partesFecha =obj.fechaDePago.split("-");
            var diasDeCorte = partesFecha[2];
            updateDoc(doc(db,"prestamos",id),{...obj,diasDeCorte}).then(()=>{
                callBack(true)   
            })
            return state
        default:
            return state;
    }

}

export {initialStore}
export default storeReducer